import React, { Component } from 'react';
import { getUser, getThisDay, getThisWeek, getThisMonth } from '../API';


class Home extends Component {

  state = {
    auth: false,
    user: {}
  };

  componentDidMount = async () => {

    const user = await getUser();
    const auth = user.profileid ? true : false;

    const todayData = await getThisDay();
    const weekData = await getThisWeek();
    const monthData = await getThisMonth();

    this.setState({
      auth,
      user,
      todayData,
      weekData,
      monthData
    });

  };

   
  handleLogin = async (e) => {

    window.open(process.env.NODE_ENV === 'production' ? 'https://medcode-willdk3000.herokuapp.com/auth/google' : 'http://localhost:8000/auth/google', "_self");

  }


  render() {
    const {auth, user, todayData, weekData, monthData} = this.state;
    return auth===true ? (

      <React.Fragment>
        <br />
        <div className="container">
            <div className="container-fluid col-sm-12 col-lg-12">
              <div className="jumbotron">
              <h1 className="display-4">Bonjour {user.prenom}</h1>
                <p className="lead">Résumé de l'activité récente :</p>

                <div className="row justify-content-center">
                <div className="card-deck text-center col-lg-12 col-sm-12">
                
                  <div className="card text-white bg-dark mb-3" style={{"Width": "20rem"}}>
                    <div className="card-header"><h5>Aujourd'hui</h5></div>
                    <div className="card-body">
                      <h3 className="card-title">{todayData.length>0 ? todayData[0].points : 0}</h3>
                    </div>
                    <div className="card-footer bg-transparent">Points entrés</div>
                  </div>

                  <div className="card text-white bg-dark mb-3" style={{"Width": "20rem"}}>
                    <div className="card-header"><h5>Cette semaine</h5></div>
                    <div className="card-body">
                      <h3 className="card-title">{weekData.length>0 ? weekData[0].points : 0}</h3>
                    </div>
                    <div className="card-footer bg-transparent">Points entrés</div>
                  </div>

                  <div className="card text-white bg-dark mb-3" style={{"Width": "20rem"}}>
                    <div className="card-header"><h5>Ce mois-ci</h5></div>
                    <div className="card-body">
                      <h3 className="card-title">{monthData.length>0 ? monthData[0].points : 0}</h3>
                    </div>
                    <div className="card-footer bg-transparent">Points entrés</div>
                  </div>

                </div>
                </div>

              </div>
            </div>
          </div>
      </React.Fragment>
    )
    :
    (
      <div className="container">
        <br /><br />
        <div className="jumbotron" id="btnbox">
          <div className="row">
            <div className="col-sm-12 col-lg-6 justify-content-center">
              <i id="intro-icon" className="fas fa-edit"><p id="intro-text">Préfacturation</p></i>
              <p id="intro-desc"> Préfacturation facile et rapide des cas complétés</p>
              <i id="intro-icon" className="fas fa-list"><p id="intro-text">Rapports</p></i>
              <p id="intro-desc"> Rapports faciles à consulter et formattés pour la saisie sur le site de la RAMQ</p>
              <i id="intro-icon" className="fas fa-chart-bar"><p id="intro-text">Statistiques</p></i>
              <p id="intro-desc"> Statistiques sommaires par semaine, par mois et par type de cas complété</p>
            </div>
            <div className="col-sm-12 col-lg-6 d-flex justify-content-center my-auto">
              <svg
                id="logoGoogle"
                className="svgIcon-use"
                width="30"
                height="37"
                viewBox="0 0 25 25"
              >
                <g fill="none" fillRule="evenodd">
                  <path
                    d="M20.66 12.693c0-.603-.054-1.182-.155-1.738H12.5v3.287h4.575a3.91 3.91 0 0 1-1.697 2.566v2.133h2.747c1.608-1.48 2.535-3.65 2.535-6.24z"
                    fill="#4285F4"
                  />
                  <path
                    d="M12.5 21c2.295 0 4.22-.76 5.625-2.06l-2.747-2.132c-.76.51-1.734.81-2.878.81-2.214 0-4.088-1.494-4.756-3.503h-2.84v2.202A8.498 8.498 0 0 0 12.5 21z"
                    fill="#34A853"
                  />
                  <path
                    d="M7.744 14.115c-.17-.51-.267-1.055-.267-1.615s.097-1.105.267-1.615V8.683h-2.84A8.488 8.488 0 0 0 4 12.5c0 1.372.328 2.67.904 3.817l2.84-2.202z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M12.5 7.38c1.248 0 2.368.43 3.25 1.272l2.437-2.438C16.715 4.842 14.79 4 12.5 4a8.497 8.497 0 0 0-7.596 4.683l2.84 2.202c.668-2.01 2.542-3.504 4.756-3.504z"
                    fill="#EA4335"
                  />
                </g>
              </svg>
              <button onClick={(e) => this.handleLogin(e)} id="logbutton">Se connecter avec Google</button>
            </div>
          </div>
        </div>
        <br />
      </div >
    );
  }
}

export default Home;