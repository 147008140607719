import React, { Component } from 'react';

class QuickAccess extends Component {
  render() {
    return (
      <div className="container-fluid col-sm-6">
        <div className="jumbotron">
          <h1 className="display-4">Accès rapide</h1>
          <p className="lead">Entrer le raccourci.</p>
          <form className="col-sm-12" onSubmit={(event) => this.props.formQuickCompleteSubmitted(event)}>
            <div className="form-group row">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  {
                    this.props.goodShortcut === 1 ?
                      <i className="col-sm-1 far fa-check-circle" style={{ color: 'green' }}></i>
                      : <i className="col-sm-1 fas fa-times-circle" style={{ color: 'red' }}></i>
                  }
                </div>
              </div>
              <input
                autoComplete="off"
                placeholder="Raccourci"
                type="text"
                className="form-control col-sm-3"
                rows="1"
                id="inputShortcut"
                onChange={(e) => this.props.handleShortcutChange(e)} />
              <div>
                <input placeholder="Quantité" type="number" className="form-control col-sm-5" rows="1" id="inputShortcutQte" value={this.props.qteQuick} onChange={this.props.handleQteQuick} />
              </div>
              {
                this.props.goodShortcut === 1 ?
                  <input className="form-control col-sm-12" type="text" placeholder={this.props.shortcutDesc} readOnly></input>
                  : <i></i>
              }
            </div>
            <div className="form-group row">
              <textarea placeholder="Notes" className="form-control col-sm-12" rows="4" id="inputShortcutNotes" />
            </div>
            <br />
            <div className="form-group row" id="validtype">
              <button type="submit" className="btn btn-primary">
                Valider
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default QuickAccess;