import React, { PureComponent } from 'react';
import { Treemap, Tooltip, ResponsiveContainer } from 'recharts';

const COLORS = ['#8889DD', '#9597E4', '#8DC77B', '#A5D297', '#E66E1F', '#F8C12D'];

class CustomizedContent extends PureComponent {

  render() {

    const {
      depth, x, y, width, height, index, colors, name, totalpts
    } = this.props;

    //autres props utiles : root, payload, rank, totalqte

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            //fix ici : le 3 correspond au nombre de categories (chirurgie, biospsie, autres = 3)
            fill: depth < 2 ? colors[Math.floor(index / 3 * 6)] : 'rgba(255,255,255,0)',
            stroke: '#fff',
            strokeWidth: 2 / (depth + 1e-10),
            strokeOpacity: 1 / (depth + 1e-10),
          }}
        />
        {
          depth === 1 ? (
            <text
              x={x + width / 2}
              y={y + height / 2 + 7}
              textAnchor="middle"
              fill="#fff"
              fontSize={18}
              fontFamily="Roboto"
              fontWeight={300}
            >
              {name}
            </text>
          ) : null
        }
        {/*{
          depth === 2 ? (
            <text
              x={x + 4}
              y={y + 18}
              fill="#fff"
              fontSize={16}
              fillOpacity={0.9}
            >
              {name}
            </text>
          ) : null
        }*/}
        {
          depth === 3 && totalpts > 100 ? (
            <text
              x={x + width / 2 - 6}
              y={y + height / 2}
              fill="#fff"
              fontSize={8}
              fillOpacity={0.8}
              fontFamily="Roboto"
              fontWeight={100}
            >
              {name}
            </text>
          ) : null
        }
      </g>
    );
  }
}

export default class Example extends PureComponent {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/u702a3Lx/';

  render() {

    const CustomTooltip = ({ active, payload }) => {
      if (active) {
        return (
          //pour etre centre verticalement, doit etre un span
          <div className="custom-tooltip-treemap">
            <span className="label-treemap">Code : {payload[0].payload.name}<br />
              Total pts : {payload[0].payload.totalpts}</span>
          </div>
        );
      }

      return null;
    };

    return (
      <ResponsiveContainer width="100%" height="100%">
        <Treemap
          width={1000}
          height={500}
          data={this.props.treeData}
          dataKey="totalpts"
          ratio={4 / 3}
          stroke="#fff"
          fill="#8884d8"
          content={<CustomizedContent colors={COLORS} />}
        >
          <Tooltip
            content={<CustomTooltip />}
          />
        </Treemap>
      </ResponsiveContainer>
    );
  }
}
