import React, { Component } from 'react';
import { getTransac, getTransacGroupby, deleteTransac } from '../API';
import DataTable from '../components/DataTable.js'
import moment from 'moment';
import DayPicker from 'react-day-picker';
import Helmet from 'react-helmet';
import 'react-day-picker/lib/style.css';
import { getWeekDays, getWeekRange } from '../helpers/daypicker-helper.js'

class Historique extends Component {

  state = {
    transactions: null,
    hoverRange: undefined,
    selectedDays: [],
  };

  componentDidMount = async (done, days, hover, view) => {
    const from = moment(this.state.selectedDays[0]).format('YYYY-MM-DD');
    const to = parseInt(moment(this.state.selectedDays[6]).format('X')) + 86399;
    const transactions = view ? await getTransacGroupby(from, to) : await getTransac();
    const selectDays = days ? days : this.state.selectedDays;
    const selectRange = hover ? hover : undefined;
    const viewFormat = view;
    this.setState({
      transactions,
      hoverRange: selectRange,
      selectedDays: selectDays,
      viewFormat
    })

  }

  handleDelete = async (e) => {
    const done = await deleteTransac({
      "id": e.target.id
    });
    this.componentDidMount(done);
  }

  handleViewFormat = async (e) => {
    if (this.state.viewFormat) {
      this.componentDidMount(null, this.state.hoverRange, this.state.selectedDays);
    } else {
      this.componentDidMount(null, this.state.hoverRange, this.state.selectedDays, 'code');
    }
  }

  handleDayChange = date => {
    this.componentDidMount(null, getWeekDays(getWeekRange(date).from));
  };

  handleDayEnter = date => {
    this.setState({
      hoverRange: getWeekRange(date),
    });
  };

  handleDayLeave = () => {
    this.setState({
      hoverRange: undefined,
    });
  };

  handleWeekClick = (weekNumber, days, e) => {
    this.setState({
      selectedDays: days,
    });

  };

  render() {
    const { hoverRange, selectedDays } = this.state;

    const daysAreSelected = selectedDays.length > 0;

    const modifiers = {
      hoverRange,
      selectedRange: daysAreSelected && {
        from: selectedDays[0],
        to: selectedDays[6],
      },
      hoverRangeStart: hoverRange && hoverRange.from,
      hoverRangeEnd: hoverRange && hoverRange.to,
      selectedRangeStart: daysAreSelected && selectedDays[0],
      selectedRangeEnd: daysAreSelected && selectedDays[6],
    };

    const { transactions } = this.state;

    return transactions
      ? <React.Fragment>
        <div className="container">
          <div className="SelectedWeekExample" style={{ backgroundColor: '#F0F0F0', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <DayPicker
              selectedDays={selectedDays}
              showWeekNumbers
              showOutsideDays
              modifiers={modifiers}
              onDayClick={this.handleDayChange}
              onDayMouseEnter={this.handleDayEnter}
              onDayMouseLeave={this.handleDayLeave}
              onWeekClick={this.handleWeekClick} />

            {this.state.selectedDays.length === 7 && (
              <div style={{ color: 'black', display: 'flex', justifyContent: 'center' }}>
                {moment(selectedDays[0]).format('LL')} –{' '}
                {moment(selectedDays[6]).format('LL')}
              </div>
            )}
          </div>
        </div><br />

        {
          this.state.viewFormat === 'code' ?
            <div className="container col-sm-5 col-md-4 col-lg-3">
              <button type="button" className="btn btn-info btn-block" onClick={(e) => this.handleViewFormat(e)}>Voir le rapport par transaction</button>
            </div>
            :
            <div className="container col-sm-5 col-md-4 col-lg-3">
              <button type="button" className="btn btn-info btn-block" onClick={(e) => this.handleViewFormat(e)}>Voir le rapport par code</button>
            </div>
        }

        <br />

        <DataTable
          transactions={this.state.transactions}
          selectedDays={this.state.selectedDays}
          viewFormat={this.state.viewFormat}
          handleDelete={this.handleDelete}
        />

        <Helmet>

          <style>{`
            .SelectedWeekExample .DayPicker-Month {
              border-collapse: separate;
            }
            .SelectedWeekExample .DayPicker-WeekNumber {
              outline: none;
            }
            .SelectedWeekExample .DayPicker-Day {
              outline: none;
              border: 1px solid transparent;
            }
            .SelectedWeekExample .DayPicker-Day--hoverRange {
              background-color: #D3D3D3 !important;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRange {
              background-color: #fff7ba !important;
              border-top-color: #FFEB3B;
              border-bottom-color: #FFEB3B;
              border-left-color: #fff7ba;
              border-right-color: #fff7ba;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRangeStart {
              background-color: #FFEB3B !important;
              border-left: 1px solid #FFEB3B;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRangeEnd {
              background-color: #FFEB3B !important;
              border-right: 1px solid #FFEB3B;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
            .SelectedWeekExample .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
              border-radius: 0 !important;
              color: black !important;
            }
            .SelectedWeekExample .DayPicker-Day--hoverRange:hover {
              border-radius: 0 !important;
              
            }
          `}</style>
        </Helmet>
      </React.Fragment>
      : (
        <div className="container">
          <div className="row justify-content-center" style={{'color':'white'}}>
            <h1>Chargement...</h1>
          </div>
        </div>
      );
  }
}

export default Historique;
