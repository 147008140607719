import React from 'react';
import { Switch, Route } from 'react-router-dom';
import NotFound from './NotFound';

import Home from '../containers/Home.js'
import { Transaction } from '../containers/Transaction';
import Historique from '../containers/Historique';
import Dashboard from '../containers/Dashboard';



const Routes = () => (
    <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/transaction" component={Transaction} />
        <Route exact path="/historique" component={Historique} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route path="*" component={NotFound} />
    </Switch>
);

export default Routes;
