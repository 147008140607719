import React, { Component } from 'react';

class TypeAccess extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="jumbotron">
          <div className="container-fluid col-sm-8">
            <h1 className="display-4">Par catégorie</h1>
            <p className="lead">Remplir tous les champs.</p>

            <form onSubmit={(event) => this.props.formCompleteSubmitted(event)}>
              <div className="form-group row">
                <label htmlFor="Cat" className="col-sm-4 col-form-label">
                  Catégorie
                </label>
                <select
                  className="form-control col-sm-8"
                  id="inputCat"
                  onChange={(e) => this.props.handleSelectCat(e)}
                  value={this.props.catSelect ? this.props.catSelect : ''}
                >
                  <option>--Choisir catégorie--</option>
                  {this.props.allcat.map((e) => {
                    return <option key={Object.keys(e) + e}>{e}</option>;
                  })}
                </select>
              </div>
              <div className="form-group row">
                <label htmlFor="Souscat" className="col-sm-4 col-form-label">
                  Sous-catégorie
                </label>
                <select
                  className="form-control col-sm-8"
                  id="inputSouscat"
                  onChange={(e) => this.props.handleSelectSouscat(e)}
                  value={this.props.souscatvalue}
                >
                  {this.props.souscatSelect ? (
                    this.props.souscatSelect.map((e) => {
                      return <option key={Object.keys(e) + e}>{e}</option>;
                    })
                  ) : (
                      <option>--Choisir sous-catégorie--</option>
                    )}
                </select>
              </div>
              <div className="form-group row">
                <label htmlFor="Desc" className="col-sm-4 col-form-label">
                  Description
                </label>
                <select className="form-control col-sm-8" id="inputDesc">
                  {this.props.descSelect ? (
                    this.props.descSelect.map((e) => {
                      return <option key={Object.keys(e) + e}>{e}</option>;
                    })
                  ) : (
                      <option>--Choisir description--</option>
                    )}
                </select>
              </div>
              <div className="form-group row">
                <label htmlFor="inputQte" className="col-sm-4 col-form-label">
                  Quantité
                </label>
                <div>
                  <input type="number" className="form-control col-sm-8" id="inputQte" value={this.props.qteType} onChange={this.props.handleQteType} />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label" htmlFor="notes">
                  Notes
                </label>
                <textarea className="form-control col-sm-12" rows="5" id="inputNotes" />
              </div>
              <div id="validtype">
                <button type="submit" className="btn btn-primary">
                  Valider
                </button>
              </div>
            </form>
          </div>
        </div >
      </div>
    );
  }
}

export default TypeAccess;