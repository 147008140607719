import React, { Component } from 'react';

class CodeAccess extends Component {
  render() {
    return (
      <div className="container-fluid col-sm-6">
        <div className="jumbotron">
          <h1 className="display-4">Par code</h1>
          <p className="lead">Entrer le code.</p>
          <form className="col-sm-12" onSubmit={(event) => this.props.formCodeCompleteSubmitted(event)}>
            <div className="form-group row">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  {
                    this.props.goodCode === 1 ?
                      <i className="col-sm-1 far fa-check-circle" style={{ color: 'green' }}></i>
                      : <i className="col-sm-1 fas fa-times-circle" style={{ color: 'red' }}></i>
                  }
                </div>
              </div>
              <input
                autoComplete="off"
                placeholder="Code"
                type="number"
                className="form-control col-sm-3"
                rows="1"
                id="inputCode"
                onChange={(e) => this.props.handleCodeChange(e)} />
              <select
                className="form-control col-sm-4"
                id="inputModif"
                onChange={(e) => this.props.handleSelectModif(e)}
                value={this.props.modifSelect ? this.props.modifSelect : ''}
              >
                <option>--Modificateur--</option>
                {this.props.allMod.map((e) => {
                  return <option key={Object.keys(e) + e}>{e}</option>;
                })}
              </select>
              {
                this.props.goodCode === 1 ?
                  <input className="form-control col-sm-12" type="text" placeholder={this.props.codeDesc} readOnly></input>
                  : <i></i>
              }
              <input placeholder="Quantité" type="number" className="form-control col-sm-3" rows="1" id="inputCodeQte" value={this.props.qteCode} onChange={this.props.handleQteCode} />
            </div>
            <div className="form-group row">
              <textarea placeholder="Notes" className="form-control col-sm-12" rows="4" id="inputCodeNotes" />
            </div>
            <br />
            <div className="form-group row" id="validtype">
              <button type="submit" className="btn btn-primary">
                Valider
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default CodeAccess;