//USER
//Get user
  export async function getUser() { 
	const response = await fetch('/api/getuser');
	return response.json();
  };


//TRANSACTION
//Get codes
export async function getCodes() {
	const response = await fetch('/api/codes')
	return response.json();
};

//Get modificateurs
export async function getModifs() {
	const response = await fetch('/api/modifs')
	return response.json();
};

//Insert transaction
export async function insertTransac(transaction) {

	const response = await fetch('/api/transactions/insert', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(transaction)
	});

	return response

}

//HISTORIQUE
//Get all transactions
export async function getTransac() {
	const response = await fetch('/api/transactions');
	return response.json();
}

//Delete transaction
export async function deleteTransac(transacid) {
	const response = await fetch('/api/transactions/delete', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(transacid)
	});

	return response;
}

//Get transaction group
export async function getTransacGroupby(from, to) {

	const response = await fetch('/api/transactionsgroup', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			datedebut: from,
			datefin: to
		})
	});

	return response.json();
}


//DASHBOARD
//Get all transactions
export async function getTransacWeek() {
	const response = await fetch('/api/transactions/getWeek');
	return response.json()
}

export async function getTransacMonth() {
	const response = await fetch('/api/transactions/getMonth');
	return response.json()
}

// Get transactions per subCat
//export async function getTransacSouscat() {
//	const response = await fetch('/api/transactions/getSousCat');
//	return response.json()
//}

export async function getTransacSouscat(yearSelect) {
	const response = await fetch('/api/transactions/getSousCat', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({year:yearSelect})
	});

	return response.json();
}




// Get years
export async function getYears() {
	const response = await fetch('/api/transactions/getYears');
	return response.json()
}



//HOME
//Get today's transactions
export async function getThisDay() {
	const response = await fetch('/api/transactions/getThisDay');
	return response.json()
}

//Get this week's transactions
export async function getThisWeek() {
	const response = await fetch('/api/transactions/getThisWeek');
	return response.json()
}

//Get this month's transactions
export async function getThisMonth() {
	const response = await fetch('/api/transactions/getThisMonth');
	return response.json()
}