import React, { Component } from 'react';
import { getTransacWeek, getTransacMonth, getTransacSouscat, getUser, getYears } from '../API';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';
import Example from '../components/TreemapRecharts.js'

class Dashboard extends Component {

  state = {
    transactions: null,
    user:{},
    years:[]
  };

  componentDidMount = async (selectedYear) => {

    const user = await getUser();
    const years = await getYears();
    const yearSelect = selectedYear ? selectedYear : years[0].year;

    const transacWeek = await getTransacWeek();
    transacWeek.forEach((t) => {
      t.weekFormat = t.week.substr(0, 10);
    })
    const transacMonth = await getTransacMonth();
    transacMonth.forEach((t) => {
      t.monthFormat = t.month.substr(0, 7);
    })

    const transacSousCat = await getTransacSouscat(yearSelect);
   
    this.setState({
      weekly: transacWeek,
      monthly: transacMonth,
      sousCat: transacSousCat[0].jsonb_build_object.data,
      user,
      years,
      yearSelect
    })
  }

  handleChangeYear(yearSelect) {
    //console.log(yearSelect.target.value);
    this.componentDidMount(yearSelect.target.value);
  }



  render() {

     const { user, years, yearSelect } = this.state;

    //Longueur de l'objet "user" > 0 ?
    return Object.keys(user).length > 0 ? (
      <div className="container">
        <br />

        <div id="chartCard">
          <h1 style={{ color: '#FFFFFF', textAlign: 'center' }}>Points par semaine</h1>
          <ResponsiveContainer width="100%" height={350}>
            <BarChart data={this.state.weekly}>
              <XAxis dataKey="weekFormat" tick={{ fill: 'white' }} />
              <YAxis tick={{ fill: 'white' }} />
              <Tooltip
                cursor={{ fill: 'rgb(216, 216, 216, 0.2)' }}
                itemStyle={{ color: "black" }} />
              < Bar dataKey="points" fill="rgb(186, 225, 255,0.75)" stroke="black" strokeWidth='1' />
            </BarChart>
          </ResponsiveContainer>
        </div >

        <br /><br />

        <div id="chartCard">
          <h1 style={{ color: '#FFFFFF', textAlign: 'center' }}>Points par mois</h1>
          <ResponsiveContainer width="100%" height={350}>
            <BarChart data={this.state.monthly}>
              <XAxis dataKey="monthFormat" tick={{ fill: 'white' }} />
              <YAxis tick={{ fill: 'white' }} />
              <Tooltip
                cursor={{ fill: 'rgb(216, 216, 216, 0.2)' }}
                itemStyle={{ color: "black" }} />
              < Bar dataKey="points" fill="rgb(186,255,201,0.75)" stroke="black" strokeWidth='1' />
            </BarChart>
          </ResponsiveContainer>
        </div >

        <br /><br />

        <div id="chartCard">
          <h1 style={{ color: '#FFFFFF', textAlign: 'center' }}>Points par code : {yearSelect}</h1>
          <ResponsiveContainer width="100%" height={500}>
            <Example
              treeData={this.state.sousCat}
            />
          </ResponsiveContainer>
        </div >
        <div className="row justify-content-center">
          {years.map((e) => (
                      <button
                        key={e.year}
                        className="btn btn-light mr-2 mt-2"
                        value={e.year}
                        onClick={(yearSelect) => this.handleChangeYear(yearSelect)}>
                        {e.year}
                      </button>
          ))
          }
        </div>

        <br /><br />

      </div>

    ) :
    (
      <div className="container">
          <div className="row justify-content-center" style={{'color':'white'}}>
            <h1>Chargement...</h1>
          </div>
      </div>
    );
  }
}

export default Dashboard;