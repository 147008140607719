import React, { Component } from 'react';
import moment from 'moment';

class DataTable extends Component {

  render() {
    return (
      <div>
        <div className="container">
          <table className="table" id="transac_list">
            <thead>
              {
                this.props.viewFormat === 'code' ?
                  <tr>
                    <th scope="col">Code</th>
                    <th scope="col">Quantité</th>
                    <th scope="col">Valeur</th>
                    <th scope="col">Modificateur</th>
                    <th scope="col">Modif. val.</th>
                    <th scope="col">Total</th>
                  </tr>
                  :
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Créée</th>
                    <th scope="col">Catégorie</th>
                    <th scope="col">Sous-catégorie</th>
                    <th scope="col">Descrption</th>
                    <th scope="col">Code</th>
                    <th scope="col">Notes</th>
                    <th scope="col">Valeur</th>
                    <th scope="col">Quantité</th>
                    <th scope="col">Sous-Total</th>
                    <th scope="col">Modificateur</th>
                    <th scope="col">Modif. val.</th>
                    <th scope="col">Total</th>
                  </tr>
              }
            </thead>
            <tbody>
              {
                this.props.viewFormat === 'code' ?
                  this.props.transactions.map(transaction => (
                    <tr key={transaction.modificateur != null ? transaction.code + transaction.modificateur : transaction.code}>
                      <td>{transaction.code}</td>
                      <td>{transaction.qte}</td>
                      <td>{transaction.val}</td>
                      <td>{transaction.modificateur}</td>
                      <td>{transaction.modval}</td>
                      <td>{transaction.modval != null ? transaction.modval * transaction.sum : transaction.sum}</td>
                    </tr>
                  ))
                  : this.props.transactions.filter((e) => {
                    if ((parseInt(moment(e.created_at).format('X')) >= parseInt(moment(this.props.selectedDays[0]).format('X'))) && (parseInt(moment(e.created_at).format('X')) <= parseInt(moment(this.props.selectedDays[6]).format('X')) + 86399)) {
                      return e
                    } else { return '' }
                  }).map(transaction => (
                    <tr key={transaction.id}>
                      <td>{transaction.id}</td>
                      <td>{moment(transaction.created_at).format('YYYY-MM-DD')}</td>
                      <td>{transaction.cat}</td>
                      <td>{transaction.souscat}</td>
                      <td>{transaction.description}</td>
                      <td>{transaction.code}</td>
                      <td>{transaction.notes}</td>
                      <td>{transaction.val}</td>
                      <td>{transaction.qte}</td>
                      <td>{transaction.qteval}</td>
                      <td>{transaction.modificateur}</td>
                      <td>{transaction.modval}</td>
                      <td>{transaction.modval != null ? transaction.modval * transaction.qteval : transaction.qteval}</td>
                      <td><button type="button" name="delete_transac" id={transaction.id} className="btn btn-danger" onClick={(e) => this.props.handleDelete(e)}>X</button></td>
                    </tr>
                  ))
              }
              {this.props.viewFormat === 'code' ?
                <tr>
                  <td colSpan="5" style={{ textAlign: 'right' }}><b>Total</b></td>
                  <td><b>{this.props.transactions.reduce((a, b) => b.modval != null ? a + (b.sum * b.modval) : a + b.sum, 0).toFixed(2)}</b></td>
                </tr>
                : <tr><td></td></tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default DataTable;