import React, { Component } from 'react';
import { getCodes, getModifs, insertTransac, getUser } from '../API';
import QuickAccess from '../components/QuickAccess';
import CodeAccess from '../components/CodeAccess';
import TypeAccess from '../components/TypeAccess';

export class Transaction extends Component {
  state = {
    codes: null,
    qteQuick: 1,
    qteCode: 1,
    qteType: 1,
    auth: false
  };

  componentDidMount = async (cat, souscat, desc, qte, modif, modifval) => {

    const user = await getUser();
    const auth = user.profileid ? true : false;

    const codes = await getCodes();
    const modifobject = await getModifs();    
    const modificateurs = [...new Set(modifobject.map((e) => { return e.mod }))];
    const allcat = [...new Set(codes.map((e) => { return e.cat; }))];
    const catSelect = cat ? cat : '';
    const souscatSelect = souscat ? souscat : null;
    const descSelect = desc ? desc : null;
    const qteSelect = qte ? qte : '';
    const modifSelect = modif ? modif : null;
    const modifValSelect = modifval ? modifval : null;

    this.setState({
      auth,
      codes,
      allcat,
      catSelect,
      souscatSelect,
      descSelect,
      qteSelect,
      modifobject,
      modificateurs,
      modifSelect,
      modifValSelect
    });

  //console.log(this.state);

  };

  //--------------------*********************LOGIN*********************-----------------------

  handleLogin = async (e) => {

    window.open(process.env.NODE_ENV === 'production' ? 'https://medcode-willdk3000.herokuapp.com/auth/google' : 'http://localhost:8000/auth/google', "_self");

  }


  //--------------------*********************Fin LOGIN********************-----------------------


  //--------------------*********************Type Access*********************-----------------------


  handleSelectCat = async (e) => {
    const selectedCat = e.target.value;
    this.handleCatChanged(selectedCat);
  };

  handleCatChanged = async (selectedCat) => {
    const codesSouscat = this.state.codes.filter((e) => {
      return e.cat === selectedCat;
    });
    const selectedSouscat = [
      ...new Set(
        codesSouscat.map((e) => {
          return e.souscat;
        })
      )
    ];
    selectedSouscat.unshift('--Choisir sous-catégorie');
    this.componentDidMount(selectedCat, selectedSouscat);
  };

  handleSelectSouscat = async (e) => {
    const selectedSouscat = e.target.value;
    this.handleSouscatChanged(selectedSouscat);
  };

  handleSouscatChanged = async (selectedSouscat) => {
    const codesDesc = this.state.codes.filter((e) => {
      return e.souscat === selectedSouscat;
    });
    const selectedDesc = [
      ...new Set(
        codesDesc.map((e) => {
          return e.description + ' [' + e.code + '] ' + e.val;
        })
      )
    ];
    this.componentDidMount(this.state.catSelect, this.state.souscatSelect, selectedDesc);
  };

  handleQteType = async (e) => {
    this.setState({
      qteType: e.target.value
    })
  }

  formCompleteSubmitted = async (event) => {
    event.preventDefault();
    if (event.target.inputDesc.value.substr(0, 2) !== '--') {
      let myRegex = /(\d{5})/;
      let selectCode = myRegex.exec(event.target.inputDesc.value)[0];
      let split = event.target.inputDesc.value.split(' ');
      let valeur = parseFloat(split[split.length - 1]);
      let descArr = [];
      for (let i = 0; i < split.length - 2; i++) {
        descArr.push(split[i]);
      }
      let descrip = descArr.join(' ');

      const transaction = {
        cat: event.target.inputCat.value,
        souscat: event.target.inputSouscat.value,
        description: descrip,
        code: selectCode,
        val: valeur,
        qte: event.target.inputQte.value,
        notes: event.target.inputNotes.value //.replace(/[.*+?^${}()<>|[\]\\]/g, '\\$&')
      };
      insertTransac(transaction);
      event.target.elements.inputNotes.value = '';
      event.target.elements.inputQte.value = '';
      this.componentDidMount();
    }
  };
  //--------------------*********************Fin Type Access*********************-----------------------

  //--------------------*********************Quick Access*********************-----------------------

  handleQteQuick = async (e) => {
    this.setState({
      qteQuick: e.target.value
    })
  }

  handleShortcutChange = async (e) => {
    if (this.state.codes.filter((i) => i.quickaccess === e.target.value).length > 0) {
      const codeChoisi = this.state.codes.filter((i) => i.quickaccess === e.target.value);
      this.setState({
        goodShortcut: 1,
        shortcutDesc: codeChoisi[0].description,
        shortcutCat: codeChoisi[0].cat,
        shortcutSouscat: codeChoisi[0].souscat,
        shortcutCode: codeChoisi[0].code,
        shortcutValeur: codeChoisi[0].val
      })
    } else {
      this.setState({
        goodShortcut: 0,
      })
    }
  };

  formQuickCompleteSubmitted = async (event) => {
    event.preventDefault();
    if (this.state.goodShortcut === 1) {
      const transaction = {
        cat: this.state.shortcutCat,
        souscat: this.state.shortcutSouscat,
        description: this.state.shortcutDesc,
        code: this.state.shortcutCode,
        val: this.state.shortcutValeur,
        qte: event.target.inputShortcutQte.value,
        notes: event.target.inputShortcutNotes.value //.replace(/[.*+?^${}()<>|[\]\\]/g, '\\$&')
      };
      insertTransac(transaction);
      event.target.elements.inputShortcut.value = '';
      event.target.elements.inputShortcutNotes.value = '';
      event.target.elements.inputShortcutQte.value = '';
      this.setState({
        goodShortcut: 0
      })
      this.componentDidMount();
    }
  }

  //--------------------*********************Fin Quick Access*********************-----------------------

  //--------------------*********************Codes Access*********************-----------------------

  handleQteCode = async (e) => {
    this.setState({
      qteCode: e.target.value
    })
  }

  handleSelectModif = async (e) => {
    const selectedModif = parseFloat(e.target.value);
    const modifVal = this.state.modifobject.filter((e) => {
      return e.mod === selectedModif;
    });
    const modifValSelect = modifVal[0].facteur;

    this.componentDidMount(
      this.state.catSelect,
      this.state.souscatSelect,
      this.state.descSelect,
      this.state.qteSelect,
      selectedModif,
      modifValSelect);
  }

  handleCodeChange = async (e) => {
    if (this.state.codes.filter((i) => i.code === parseInt(e.target.value)).length > 0) {
      const codeChoisi = this.state.codes.filter((i) => i.code === parseInt(e.target.value));
      this.setState({
        goodCode: 1,
        codeDesc: codeChoisi[0].description,
        codeCat: codeChoisi[0].cat,
        codeSouscat: codeChoisi[0].souscat,
        codeCode: codeChoisi[0].code,
        codeValeur: codeChoisi[0].val
      })
    } else {
      this.setState({
        goodCode: 0,
      })
    }
  };

  formCodeCompleteSubmitted = async (event) => {
    event.preventDefault();
    if (this.state.goodCode === 1) {
      const transaction = {
        cat: this.state.codeCat,
        souscat: this.state.codeSouscat,
        description: this.state.codeDesc,
        code: this.state.codeCode,
        val: this.state.codeValeur,
        qte: event.target.inputCodeQte.value,
        notes: event.target.inputCodeNotes.value, //.replace(/[.*+?^${}()<>|[\]\\]/g, '\\$&')
        modificateur: this.state.modifSelect,
        modifval: this.state.modifValSelect
      };
      insertTransac(transaction);
      event.target.elements.inputCode.value = '';
      event.target.elements.inputCodeNotes.value = '';
      event.target.elements.inputCodeQte.value = '';
      this.setState({
        goodCode: 0
      })
      this.componentDidMount();
    }
  }
  //--------------------*********************Fin Code Access*********************-----------------------


  render() {
    const { codes } = this.state;
    return codes ? (
      <React.Fragment>
        <br />
        <div className="container">
          <div className="row">
            <QuickAccess
              formQuickCompleteSubmitted={this.formQuickCompleteSubmitted}
              goodShortcut={this.state.goodShortcut}
              handleShortcutChange={this.handleShortcutChange}
              shortcutDesc={this.state.shortcutDesc}
              qteQuick={this.state.qteQuick}
              handleQteQuick={this.handleQteQuick}
            />
            <CodeAccess
              formCodeCompleteSubmitted={this.formCodeCompleteSubmitted}
              goodCode={this.state.goodCode}
              handleCodeChange={this.handleCodeChange}
              codeDesc={this.state.codeDesc}
              qteCode={this.state.qteCode}
              handleQteCode={this.handleQteCode}
              handleSelectModif={this.handleSelectModif}
              modifSelect={this.state.modifSelect}
              allMod={this.state.modificateurs}
            />
          </div>
        </div>
        <div className="container">
          <div className="row">
            <TypeAccess
              formCompleteSubmitted={this.formCompleteSubmitted}
              handleSelectCat={this.handleSelectCat}
              catSelect={this.state.catSelect}
              allcat={this.state.allcat}
              handleSelectSouscat={this.handleSelectSouscat}
              souscatValue={this.state.souscatValue}
              souscatSelect={this.state.souscatSelect}
              descSelect={this.state.descSelect}
              qteType={this.state.qteType}
              handleQteType={this.handleQteType}
            />
          </div>
        </div>

      </React.Fragment >
    ):
    (
      <div className="container">
          <div className="row justify-content-center" style={{'color':'white'}}>
            <h1>Chargement...</h1>
          </div>
      </div>
    );
  }
}
