import React from 'react';

const NotFound = () => (
    <div className = "container">
        <div className = "jumbotron">
            <div className="container">
                <h1 className="display-4">Page introuvable...</h1>
                <p className="lead">La page que vous cherchez n'est pas disponible.</p>
            </div>
        </div>
    </div>
)

export default NotFound;