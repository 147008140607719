import React from 'react';
import { Link } from 'react-router-dom';


const Header = () => {

  const handleLogout = () => {
    window.open(process.env.NODE_ENV === 'production' ? 'https://medcode-willdk3000.herokuapp.com/auth/logout' : 'http://localhost:8000/auth/logout', "_self");
  }

  return (
  <React.Fragment>

    <div id="headnav" className="container">
      <nav className="navbar navbar-expand-md">
        <a className="navbar-brand mb-0 h1 text-white" href="/"><i className="fas fa-user-md"></i> Medcodes</a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <i className="fas fa-bars" style={{ color: '#FFFFFF' }}></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <Link className="nav-item nav-link text-white" to="/transaction" style={{ textDecoration: 'none', color: '#FFFFFF' }}>
              Transaction
            </Link>
            <Link className="nav-item nav-link text-white" to="/historique" style={{ textDecoration: 'none', color: '#FFFFFF' }}>
              Historique
            </Link>
            <Link className="nav-item nav-link text-white" to="/dashboard" style={{ textDecoration: 'none', color: '#FFFFFF' }}>
              Tableau de bord
            </Link>
          </div>
            <div className="navbar-nav ml-auto">
            <div
            className="nav-item nav-link text-white ml-auto" 
            style={{ textDecoration: 'none', color: '#FFFFFF', cursor:'pointer' }} 
            onClick={handleLogout}>Déconnexion</div>
            {
            //<Link className="nav-item nav-link text-white ml-auto" to="/dashboard" style={{ textDecoration: 'none', color: '#FFFFFF' }}>
            //</Link>
            }
          </div>
        </div>
      </nav>
    </div>

  </React.Fragment>
  )
  };

export default Header;